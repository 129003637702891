<template>
  <section id='service-list-module'>
    <div class='row'>
      <div class='col-12 d-flex align-items-center'>
        <h6 class='my-3'>Ecosystem Vendor’s Services</h6>

        <b-button variant='primary' class='ml-auto' to='/care-ecosystem/services/new'>
          <feather type='plus' />
          Add Vendor Service
        </b-button>
      </div>
    </div>
    <table class='table mx-0 mb-2'>
      <thead class='thead-light'>
      <tr>
        <th scope='col'>ID</th>
        <th scope='col'>Service name</th>
        <th scope='col'>Description</th>
        <th scope='col'></th>
        <th scope='col'>Actions</th>
      </tr>
      </thead>
      <tbody>
        <template v-if='Array.isArray(services) && services.length'>
          <tr v-for='(service, serviceIndex) in services' :key='serviceIndex'>
            <td class='align-middle'> {{ service.id }}</td>
            <td class='align-middle'> {{ service.name }}</td>
            <td class='align-middle'> {{ service.description }}</td>
            <td class='align-middle'></td>
            <td class='align-middle table__item-actions'>
              <b-button v-b-tooltip.hover
                        title='Edit'
                        size='sm'
                        variant='light'
                        @click='editService(service.id)'>
                <feather type='edit-2'></feather>
              </b-button >
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan='5' class='text-center'>No services added, add one using the “New service“ button above</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ServiceList',
  data() {
    return {
      services: [],
      loadingPage: false,
    };
  },
  mounted() {
    this.fetchServices();
  },
  methods: {
    onPageChange() {
      this.fetchServices();
    },
    fetchServices() {
      this.loadingPage = true;

      axios.get('v1/care-ecosystem/services').then(response => {
        this.services = response.data.entries;
      }).catch(err => {
        this.$noty.error('Failed to fetch care ecosystem services: ', err);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    editService(id) {
      this.$router.push({ name: 'CareEcosystemServicesEdit', params: { id } });
    },
  },
};
</script>

<style lang='scss'>
.table {
  table-layout: fixed;

  &__item {
    &:hover {
      cursor: pointer;
      background-color: $context-color-7;
    }

    td {
      align-items: center;
      @extend %compact-text;
    }

    &-actions {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0.25rem;
      text-overflow: initial !important;

      &-button {
        min-width: initial !important;
      }
    }
  }
}
</style>
